import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Locale } from "../../../../../__generated__/globalTypes";
import { Faqs, FaqsVariables } from "./__generated__/Faqs";

const FaqsQuery = gql`
  query Faqs($token: String!, $locale: Locale!, $page: Float, $limit: Float) {
    faqs(token: $token, locale: $locale, page: $page, limit: $limit) {
      pageInfo {
        hasNextPage
        count
        page
        limit
      }
      result {
        id
        priority
        title
        text
        active
      }
    }
  }
`;

export const useFaqs = ({
  page,
  limit,
}: {
  page?: number;
  limit?: number;
} = {}) => {
  const token = useUserToken();
  const res = useQuery<Faqs, FaqsVariables>(FaqsQuery, {
    skip: !token,
    variables: {
      token,
      locale: Locale.fr,
      limit: limit || 30,
      page: page || 1,
    },
  });

  const pageInfo = (res.data &&
    res.data.faqs &&
    res.data.faqs.pageInfo) || {
    page: 1,
    hasNextPage: false,
  };

  return {
    ...res,
    faqs: (res.data && res.data.faqs && res.data.faqs.result) || [],
    pageInfo,
    onLoadMore: async () => {
      if (!pageInfo.hasNextPage || res.loading) {
        return Promise.resolve();
      }

      return res.fetchMore({
        variables: {
          page: pageInfo.page + 1,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }

          return {
            faqs: {
              ...prev.faqs,
              result: [
                ...prev.faqs.result,
                ...fetchMoreResult.faqs.result,
              ],
              pageInfo: fetchMoreResult.faqs.pageInfo,
            },
          };
        },
      });
    },
  };
};
