import {
  Button,
  Dropdown,
  Form,
  IDropdownItem,
  useInputProps,
} from "@royalcanin-fr-loyalty/ui-kit";
import { omit } from "lodash";
import React from "react";
import styled from "styled-components";
import { UserRoles_userRoles_result } from "../lib/hooks/__generated__/UserRoles";

const Submit = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

export interface IRoleSelectorProps {
  roles: UserRoles_userRoles_result[];
  currentRole: UserRoles_userRoles_result;
  onSubmit: (role: IDropdownItem<UserRoles_userRoles_result>["value"]) => void;
}

const isSameRole = (
  role1: UserRoles_userRoles_result,
  role2: UserRoles_userRoles_result,
) => {
  return (
    role1.partnerRelation.extId === role2.partnerRelation.extId &&
    role1.roleDefinition.id === role2.roleDefinition.id
  );
};

export const RoleSelector = ({
  roles,
  currentRole,
  onSubmit,
}: IRoleSelectorProps) => {
  return (
    <>
      <Form
        initialValues={{
          roleIndex: roles.findIndex(r => isSameRole(r, currentRole)),
        }}
        onSubmit={({ roleIndex }) => onSubmit(roles[roleIndex])}
        render={({ handleSubmit, submitting, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Dropdown<number>
              {...omit(useInputProps({ name: "roleIndex" }), ["helperText"])}
              fullWidth
              items={roles.map((role, i) => ({
                label: role.name || "",
                value: i,
              }))}
            />
            <Submit>
              <Button type="submit" disabled={invalid || submitting}>
                Sélectionner
              </Button>
            </Submit>
          </form>
        )}
      />
    </>
  );
};
