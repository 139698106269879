import { useUserContext } from "@igloo-be-omnipartners/hooks";
import { defaultLocale } from "@royalcanin-fr-loyalty/settings";
import {
  Layout as DefaultLayout,
  Modal,
  ModalHeader,
} from "@royalcanin-fr-loyalty/ui-kit";
import React, { useState } from "react";
import footerLinks from "../../../datastore/data/settingsFooterLinks.json";
import { useFaqs } from "../lib/hooks/useFaqs";
import { useFetchUserRole, useUserRole } from "../lib/hooks/useUserRole";
import { useUserRoles } from "../lib/hooks/useUserRoles";
import { UserRole_userRole_result } from "../lib/hooks/__generated__/UserRole";
import { ModalLogout } from "./ModalLogout";
import { RoleSelector } from "./RoleSelector";

type ExcludesFalse = <T>(x: T | false | undefined | null | "" | 0) => x is T;

const useNavLinks = (
  currentRole: UserRole_userRole_result | null | undefined,
) => {
  const currentPath =
    typeof window !== "undefined" &&
    window.location.pathname.replace(/\/$/, "");
  const sectionEnabledLitters =
    currentRole &&
    currentRole.roleDefinition.sectionEnabled.indexOf("litters") >= 0;
  const contactLink = currentRole && currentRole.roleDefinition.contactLink;
  const { faqs } = useFaqs({
    limit: 1,
    page: 0,
  });

  return [
    sectionEnabledLitters && {
      active: currentPath === "/litters",
      label: "Mes portées",
      to: "/litters",
    },
    {
      active: currentPath === "/points",
      label: "Mes couronnes",
      to: "/points",
    },
    {
      active: currentPath === "/catalog",
      label: "Catalogue",
      to: "/catalog",
    },
    {
      active: currentPath === "/orders",
      label: "Commandes",
      to: "/orders",
    },
    faqs.length > 0 && {
      active: currentPath === "/faqs",
      label: "FAQ",
      to: "/faqs",
    },
    contactLink && {
      active: false,
      label: "Contact",
      to: contactLink,
      target: "_blank",
    },
  ].filter((Boolean as any) as ExcludesFalse);
};

const RoleSelectorModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: roles } = useUserRoles();
  const { setToken } = useUserContext();
  const { data: currentRole } = useUserRole();
  const fetchUserRole = useFetchUserRole();

  if (!currentRole) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose}>Sélectionnez votre entité</ModalHeader>
      <RoleSelector
        roles={roles || []}
        currentRole={currentRole}
        onSubmit={async (role) => {
          await fetchUserRole({ token: role.token });
          setToken(role.token);
          onClose();
        }}
      />
    </Modal>
  );
};

export const Layout = React.forwardRef(
  (
    {
      children,
      onVisibilitySensorChange,
      noPaddingTop,
      LayoutComponent = DefaultLayout,
    }: {
      children: React.ReactNode;
      onVisibilitySensorChange?: (arg: boolean) => void;
      noPaddingTop?: boolean;
      LayoutComponent?: React.ComponentType<
        React.ComponentProps<typeof DefaultLayout>
      >;
    },
    ref,
  ) => {
    const [modalPartnerIsOpen, setModalPartnerIsOpen] = useState(false);
    const [modalLogoutIsOpen, setModalLogoutIsOpen] = useState(false);
    const [menuMobileIsOpen, setMenuMobileIsOpen] = useState(false);
    // const logout = useUserLogout();
    const { data: currentRole, loading: currentRoleLoading } = useUserRole();
    const { data: roles } = useUserRoles();
    const hideHeader = typeof window === "undefined" || currentRoleLoading;
    const navLinks = useNavLinks(currentRole);

    const loginAs = currentRole?.isLoginAs;
    const loginAsBannerClick = () => {
      setModalLogoutIsOpen(true);
    };

    const logout = () => {
      window.location.href = "/api/okta/logout";
    };

    return (
      <>
        <ModalLogout
          isOpen={modalLogoutIsOpen}
          onClose={() => setModalLogoutIsOpen(false)}
          onSubmit={() => {
            setModalLogoutIsOpen(false);
            logout();
          }}
        />
        <RoleSelectorModal
          isOpen={modalPartnerIsOpen}
          onClose={() => setModalPartnerIsOpen(false)}
        />

        <LayoutComponent
          ref={ref}
          layoutHeaderProps={{
            layoutNavigationProps: {
              navLinks,
              partnerName: currentRoleLoading
                ? "-"
                : (currentRole && currentRole.name) || "-",
              canChangePartner: roles ? roles.length > 1 : false,
              onPartnerChangeClick: () => setModalPartnerIsOpen(true),
              onLogoutClick: () => setModalLogoutIsOpen(true),
            },
            logo:
              currentRole && currentRole.roleDefinition.logo
                ? currentRole.roleDefinition.logo
                : "",
            logoLink: currentRole?.roleDefinition.logoLink || undefined,
            logoMainHref: "/points",
            hideHeader,
          }}
          layoutFooterProps={{
            locale: defaultLocale,
            footerLinks: footerLinks.map((footerLink) => ({
              ...footerLink,
              target: footerLink.target as "_blank",
            })),
          }}
          layoutHeaderMobileProps={{
            menuMobileOpen: menuMobileIsOpen,
            onOpen: () => setMenuMobileIsOpen(true),
            onClose: () => setMenuMobileIsOpen(false),
            loginAs,
            loginAsBannerProps: {
              onClick: loginAsBannerClick,
            },
          }}
          layoutMenuMobileProps={{
            isOpen: menuMobileIsOpen,
            navLinks,
            onPartnerChangeClick: () => setModalPartnerIsOpen(true),
            onLogoutClick: (e) => {
              e.preventDefault();
              logout();
            },
            partnerName: currentRole?.partnerRelation.partner.name || "",
            loginAs,
          }}
          onVisibilitySensorChange={onVisibilitySensorChange}
          noPaddingTop={noPaddingTop}
          loginAs={loginAs}
          loginAsBannerProps={{ onClick: loginAsBannerClick }}
        >
          {children}
        </LayoutComponent>
      </>
    );
  },
);
