import {
  Button,
  Form,
  Modal,
  ModalHeader,
} from "@royalcanin-fr-loyalty/ui-kit";
import React from "react";
import styled from "styled-components";

const Submit = styled.div`
  display: flex;
`;

const Cancel = styled.div`
  display: flex;
`;

const ModalFooter = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`;

export interface IModalLogoutProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const ModalLogout = ({
  isOpen,
  onClose,
  onSubmit,
}: IModalLogoutProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, invalid }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader onClose={onClose}>Se déconnecter</ModalHeader>
            <div>Voulez-vous vous déconnecter ?</div>
            <ModalFooter>
              <Cancel>
                <Button onClick={onClose} type="button">
                  Non
                </Button>
              </Cancel>
              <Submit>
                <Button type="submit" disabled={invalid || submitting}>
                  Oui
                </Button>
              </Submit>
            </ModalFooter>
          </form>
        )}
      />
    </Modal>
  );
};
